import axios from "axios";

require("dotenv").config();

export const LOGIN_USER_KEY = "LOGIN_USER_KEY";
const {
  REACT_APP_ENVIRONMENT,
  REACT_APP_API_BASE_URL_PROD,
  REACT_APP_API_BASE_URL_DEV,
} = process.env;
let baseURL;

if (REACT_APP_ENVIRONMENT === "PRODUCTION") {
  baseURL = REACT_APP_API_BASE_URL_PROD;
} else {
  baseURL = REACT_APP_API_BASE_URL_DEV;
}

const api = axios.create({
  baseURL: baseURL,
  headers: {
    "Content-Type": "application/json",
  },
});

api.interceptors.request.use(
  (config) => {
    if (config.requireToken) {
      const localUser = localStorage.getItem(LOGIN_USER_KEY);
      const data = localUser ? JSON.parse(localUser) : null;
      config.headers.common[
        "Authorization"
      ] = `Bearer ${data.user.accessToken}`;
    }

    return config;
  },
  (err) => console.error(err)
);

api.interceptors.response.use(
  (response) => {
    return response.data;
  },
  (error) => {
    if (error.response.status === 401) {
      // localStorage.removeItem(LOGIN_USER_KEY);
    }

    return Promise.reject(error);
  }
);

export default class API {
  // Auth
  register = async (registerBody) => {
    return api.post("admin/auth/register/", registerBody);
  };

  login = async (loginBody) => {
    return api.post("admin/auth/login/", loginBody);
  };

  // Supply Item
  getSupplyItems = (query = {}) => {
    return api.get("/admin/supply-items/", {
      params: query,
      requireToken: true,
    });
  };

  getSupplyItemDetail = (id) => {
    return api.get(`/admin/supply-items/${id}`, {
      requireToken: true,
    });
  };

  createSupplyItem = (supplyItemBody) => {
    return api.post(`/admin/supply-items/`, supplyItemBody, {
      requireToken: true,
    });
  };

  updateSupplyItem = (supplyItemBody, id) => {
    return api.put(`/admin/supply-items/${id}`, supplyItemBody, {
      requireToken: true,
    });
  };

  uploadImage = (image) => {
    let data = new FormData();
    data.append("files", image);
    return api.post(`/uploads/images`, data, {
      requireToken: true,
    });
  };

  toggleSupplyItemStatus = (id) => {
    return api.put(
      `/admin/supply-items/${id}/toggle-status`,
      {},
      {
        requireToken: true,
      }
    );
  };

  // Tax Type
  getTaxTypes = (query = {}) => {
    return api.get("/admin/tax-types/", {
      params: query,
      requireToken: true,
    });
  };

  addTaxType = (taxTypeBody) => {
    return api.post("/admin/tax-types/", taxTypeBody, { requireToken: true });
  };

  updateTaxType = (taxTypeBody, id) => {
    return api.put(`/admin/tax-types/${id}`, taxTypeBody, {
      requireToken: true,
    });
  };

  // Complaint
  getComplaints = (query = {}) => {
    return api.get("/admin/complaints/", {
      params: query,
      requireToken: true,
    });
  };

  getComplaint = (id) => {
    return api.get(`/admin/complaints/${id}`, {
      requireToken: true,
    });
  };

  updateComplaint = (complaintBody, id) => {
    return api.put(`/admin/complaints/${id}`, complaintBody, {
      requireToken: true,
    });
  };

  acceptedComplaint = (id) => {
    return api.put(
      `/admin/complaints/${id}/accepted`,
      {},
      {
        requireToken: true,
      }
    );
  };
}
