import Box from "@mui/material/Box";
import CssBaseline from "@mui/material/CssBaseline";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import moment from "moment";
import React, { useState } from "react";
import { useEffect } from "react";
import { useHistory } from "react-router-dom";

import API from "../API";
import AppBarHeader from "../components/general/AppBarHeader";
import DrawerSidebar from "../components/general/DrawerSidebar";
import MainSection from "../components/general/MainSection";

const mdTheme = createTheme();

export default function SupplyItem() {
  const history = useHistory();

  const [supplyItems, setSupplyItems] = useState([]);
  const [pagination, setPagination] = useState({
    limit: 100,
    offset: 0,
    page: 1,
    total: 0,
  });

  const isSuccess = history.location.state
    ? history.location.state.isSuccess
    : "";

  const [open, setOpen] = useState(true);
  const toggleDrawer = () => {
    setOpen(!open);
  };

  const createData = (
    id,
    no,
    itemType,
    itemImageLandscapeUrl,
    itemImagePortraitUrl,
    status,
    createdAt
  ) => {
    const statusText = status === "1" ? "Active" : "Inactive";
    const createData = moment(createdAt).format("MMMM Do YYYY");
    return {
      id,
      no,
      itemType,
      itemImageLandscapeUrl,
      itemImagePortraitUrl,
      status: statusText,
      createdAt: createData,
    };
  };

  const addNewHandler = () => {
    history.push({
      pathname: `/supply-item/create`,
      state: { action: "Create" },
    });
  };

  const updateHandler = (id) => {
    history.push({
      pathname: `/supply-item/update/${id}`,
      state: { action: "Update", id },
    });
  };

  const columns = [
    { id: "no", label: "No", minWidth: 100, align: "left" },
    { id: "itemType", label: "Item Type", minWidth: 170 },

    { id: "status", label: "Status", minWidth: 170, align: "center" },
    { id: "createdAt", label: "Created Date", minWidth: 170 },
  ];

  const api = new API();

  useEffect(() => {
    api
      .getSupplyItems()
      .then((res) => {
        setSupplyItems(res.data);
        setPagination(res.metadata);
      })
      .catch((err) => {
        console.error(err);
      });
    // eslint-disable-next-line
  }, []);

  const rows = supplyItems.map((item, index) => {
    if (item) {
      return createData(
        item.id,
        index + 1,
        item.itemType,
        item.itemImageLandscapeUrl,
        item.itemImagePortraitUrl,
        item.status,
        item.createdAt
      );
    }
    return null;
  });

  return (
    <ThemeProvider theme={mdTheme}>
      <Box sx={{ display: "flex" }}>
        <CssBaseline />
        <AppBarHeader
          headerName="Supply Item"
          open={open}
          toggleDrawer={toggleDrawer}
        />
        <DrawerSidebar
          menu="supply-item"
          open={open}
          toggleDrawer={toggleDrawer}
        />
        <MainSection
          columns={columns}
          rows={rows}
          pagination={pagination}
          addNewHandler={addNewHandler}
          updateHandler={updateHandler}
          addNew={true}
          isSuccess={isSuccess}
          title="Supply Item List"
        />
      </Box>
    </ThemeProvider>
  );
}
