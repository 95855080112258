import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import {
  Button,
  Container,
  Grid,
  IconButton,
  Paper,
  styled,
  TextField,
  Toolbar,
} from "@mui/material";
import Box from "@mui/material/Box";
import CssBaseline from "@mui/material/CssBaseline";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import React, { useRef, useState } from "react";
import { useEffect } from "react";
import { useHistory } from "react-router-dom";
import Swal from "sweetalert2";

import API from "../API";
import uploadImage from "../assets/images/upload-area.svg";
import AppBarHeader from "../components/general/AppBarHeader";
import DrawerSidebar from "../components/general/DrawerSidebar";

const mdTheme = createTheme();

export default function CreateUpdateSupplyItem() {
  const api = new API();
  const [imageLandscape, setImageLandscape] = useState(null);
  const [imagePortrait, setImagePortrait] = useState(null);
  const [previewImageLandscape, setPreviewImageLandscape] = useState(null);
  const [previewImagePortrait, setPreviewImagePortrait] = useState(null);
  const inputFileLandscape = useRef(null);
  const inputFilePortrait = useRef(null);

  const VisuallyHiddenInput = styled("input")({
    clip: "rect(0 0 0 0)",
    clipPath: "inset(50%)",
    height: 1,
    overflow: "hidden",
    position: "absolute",
    bottom: 0,
    left: 0,
    whiteSpace: "nowrap",
    width: 1,
  });

  const history = useHistory();
  const [open, setOpen] = useState(true);
  const [isUpdate, setIsUpdate] = useState(false);

  const action = history.location.state ? history.location.state.action : "";
  const id = history.location.state ? history.location.state.id : null;

  const toggleDrawer = () => {
    setOpen(!open);
  };
  const [values, setValues] = useState({
    itemType: "",
    itemImageLandscape: "",
    itemImageLandscapeUrl: "",
    itemImagePortrait: "",
    itemImagePortraitUrl: "",
  });

  const inputImageLandscape = (event) => {
    const file = event.target.files[0];
    const objectUrl = URL.createObjectURL(file);
    setPreviewImageLandscape(objectUrl);
    setImageLandscape(file);
  };

  const inputImagePortrait = (event) => {
    const file = event.target.files[0];
    const objectUrl = URL.createObjectURL(file);
    setPreviewImagePortrait(objectUrl);
    setImagePortrait(file);
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;

    setValues({
      ...values,
      [name]: value,
    });
  };

  const handleSubmit = () => {
    if (isUpdate) {
      const uploadPromises = [];
      if (imageLandscape) {
        uploadPromises.push(api.uploadImage(imageLandscape));
      } else {
        uploadPromises.push(new Promise((resolve) => resolve(false)));
      }
      if (imagePortrait) {
        uploadPromises.push(api.uploadImage(imagePortrait));
      } else {
        uploadPromises.push(new Promise((resolve) => resolve(false)));
      }
      Promise.all(uploadPromises)
        .then((res) => {
          api
            .updateSupplyItem(
              {
                itemType: values.itemType,
                itemImageLandscape: res[0]
                  ? res[0].data[0].filename
                  : values.itemImageLandscape,
                itemImagePortrait: res[1]
                  ? res[1].data[0].filename
                  : values.itemImagePortrait,
              },
              id
            )
            .then(() => {
              Swal.fire({ title: "Success", icon: "success" }).then(() => {
                history.push({ pathname: "/supply-item" });
              });
            })
            .catch((err) => {
              console.error(err);
            });
        })
        .catch((err) => {
          console.error(err);
        });
    } else {
      const uploadPromises = [];
      if (imageLandscape) {
        uploadPromises.push(api.uploadImage(imageLandscape));
      } else {
        uploadPromises.push(new Promise((resolve) => resolve(false)));
      }
      if (imagePortrait) {
        uploadPromises.push(api.uploadImage(imagePortrait));
      } else {
        uploadPromises.push(new Promise((resolve) => resolve(false)));
      }
      Promise.all(uploadPromises)
        .then((res) => {
          api
            .createSupplyItem(
              {
                itemType: values.itemType,
                itemImageLandscape: res[0]
                  ? res[0].data[0].filename
                  : values.itemImageLandscape,
                itemImagePortrait: res[1]
                  ? res[1].data[0].filename
                  : values.itemImagePortrait,
              },
              id
            )
            .then(() => {
              Swal.fire({ title: "Success", icon: "success" }).then(() => {
                history.push({ pathname: "/supply-item" });
              });
            })
            .catch((err) => {
              console.error(err);
            });
        })
        .catch((err) => {
          console.error(err);
        });
      // if (imageLandscape) {
      //   api.uploadImage(imageLandscape).then((res) => {
      //     setImageLandscapeUrl(res.data[0].filename);
      //   });
      // }
      // if (imagePortrait) {
      //   api.uploadImage(imagePortrait).then((res) => {
      //     setImagePortraitUrl(res.data[0].filename);
      //   });
      // }
      // api
      //   .createSupplyItem({
      //     itemType: values.itemType,
      //     itemImageLandscape: imageLandscapeUrl,
      //     itemImagePortrait: imagePortraitUrl,
      //   })
      //   .then(() => {
      //     Swal.fire({ title: "Success", icon: "success" }).then(() => {
      //       history.push({ pathname: "/supply-item" });
      //     });
      //   })
      //   .catch((err) => {
      //     console.error(err);
      //   });
    }
  };

  useEffect(() => {
    if (action === "Update") {
      api.getSupplyItemDetail(id).then((res) => {
        setValues(res.data);
      });
    }
    setIsUpdate(action === "Update");
    // eslint-disable-next-line
  }, []);

  return (
    <>
      <ThemeProvider theme={mdTheme}>
        <Box sx={{ display: "flex" }}>
          <CssBaseline />
          <AppBarHeader
            headerName="Supply Item"
            open={open}
            toggleDrawer={toggleDrawer}
          />
          <DrawerSidebar
            menu="supply-item"
            open={open}
            toggleDrawer={toggleDrawer}
          />
          <Box
            component="main"
            sx={{
              backgroundColor: (theme) =>
                theme.palette.mode === "light"
                  ? theme.palette.grey[100]
                  : theme.palette.grey[900],
              flexGrow: 1,
              height: "100vh",
              overflow: "auto",
            }}
          >
            <Toolbar />
            <Container maxWidth="xl" sx={{ mt: 4, mb: 4 }}>
              <div
                className="add-new-button"
                style={{ justifyContent: "start" }}
              >
                <IconButton onClick={() => history.goBack()}>
                  <ArrowBackIcon />
                </IconButton>
                <p>{action} Supply Item</p>
              </div>
              <Grid item lg={12}>
                <Paper
                  sx={{
                    p: 2,
                    display: "flex",
                    flexDirection: "column",
                    textAlign: "right",
                  }}
                >
                  <Box
                    sx={{
                      alignItems: "center",
                    }}
                  >
                    <Grid container spacing={3}>
                      <Grid item lg={12}>
                        <TextField
                          autoFocus
                          fullWidth
                          id="itemType"
                          label="Item Type"
                          name="itemType"
                          onChange={handleInputChange}
                          required
                          value={values.itemType || ""}
                        />
                      </Grid>
                      {values.itemImageLandscapeUrl ? (
                        <Grid
                          item
                          md={12}
                          style={{ display: "flex", justifyContent: "center" }}
                        >
                          <img
                            style={{
                              width: "70%",
                              border: "2px solid #ffd801",
                            }}
                            src={
                              previewImageLandscape
                                ? previewImageLandscape
                                : values.itemImageLandscapeUrl
                            }
                            alt="item"
                          />
                        </Grid>
                      ) : (
                        <Grid
                          item
                          md={12}
                          style={{ display: "flex", justifyContent: "center" }}
                        >
                          <img
                            style={{ width: "50%" }}
                            src={
                              previewImageLandscape
                                ? previewImageLandscape
                                : uploadImage
                            }
                            alt="item"
                          />
                        </Grid>
                      )}

                      <Grid
                        item
                        md={12}
                        style={{ display: "flex", justifyContent: "center" }}
                      >
                        <Button
                          style={{ marginBottom: "18px" }}
                          component="label"
                          variant="contained"
                          startIcon={<CloudUploadIcon />}
                        >
                          Upload Web Image
                          <VisuallyHiddenInput
                            ref={inputFileLandscape}
                            onChange={inputImageLandscape}
                            type="file"
                          />
                        </Button>
                      </Grid>
                    </Grid>

                    <Grid container spacing={3}>
                      {values.itemImagePortraitUrl ? (
                        <Grid
                          item
                          md={12}
                          style={{
                            display: "flex",
                            justifyContent: "center",
                          }}
                        >
                          <img
                            style={{
                              width: "70%",
                              border: "2px solid #ffd801",
                            }}
                            src={
                              previewImagePortrait
                                ? previewImagePortrait
                                : values.itemImagePortraitUrl
                            }
                            alt="item"
                          />
                        </Grid>
                      ) : (
                        <Grid
                          item
                          md={12}
                          style={{
                            display: "flex",
                            justifyContent: "center",
                          }}
                        >
                          <img
                            style={{ width: "50%" }}
                            src={
                              previewImagePortrait
                                ? previewImagePortrait
                                : uploadImage
                            }
                            alt="item"
                          />
                        </Grid>
                      )}

                      <Grid
                        item
                        md={12}
                        style={{
                          display: "flex",
                          justifyContent: "center",
                        }}
                      >
                        <Button
                          component="label"
                          variant="contained"
                          startIcon={<CloudUploadIcon />}
                        >
                          Upload Mobile Image
                          <VisuallyHiddenInput
                            ref={inputFilePortrait}
                            onChange={inputImagePortrait}
                            type="file"
                          />
                        </Button>
                      </Grid>
                    </Grid>

                    <Button
                      disabled={
                        isUpdate
                          ? false
                          : values.itemType && imagePortrait && imageLandscape
                          ? false
                          : true
                      }
                      type="button"
                      onClick={handleSubmit}
                      variant="contained"
                      sx={{ mt: 3, mb: 2 }}
                    >
                      {action}
                    </Button>
                  </Box>
                </Paper>
              </Grid>
            </Container>
          </Box>
        </Box>
      </ThemeProvider>
    </>
  );
}
