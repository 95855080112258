import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import AutoAwesomeMosaicIcon from "@mui/icons-material/AutoAwesomeMosaic";
import { Divider, IconButton, List, Toolbar } from "@mui/material";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import React from "react";
import { useHistory } from "react-router";

import Drawer from "./Drawer";

export default function DrawerSidebar(props) {
  const history = useHistory();
  const { open, toggleDrawer } = props;
  return (
    <>
      <Drawer variant="permanent" open={open}>
        <Toolbar
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "flex-end",
            px: [1],
          }}
        >
          <IconButton onClick={toggleDrawer}>
            <ChevronLeftIcon />
          </IconButton>
        </Toolbar>
        <Divider />
        <List style={{ cursor: "pointer" }}>
          <ListItem
            onClick={() => history.push("/supply-item")}
            className="active"
          >
            <ListItemIcon>
              <AutoAwesomeMosaicIcon style={{ fill: "#FFF" }} />
            </ListItemIcon>
            <ListItemText primary="Supply Item" />
          </ListItem>
        </List>
      </Drawer>
    </>
  );
}
